var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          "data-source": _vm.list,
          pagination: _vm.pagination,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemNatureSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", { class: _vm.natureTypeClassType[row.natureType] }, [
                  _vm._v(_vm._s(_vm.natureTypeListMapText(row.natureType)))
                ])
              ])
            }
          },
          {
            key: "itemImgListSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.imgList, function(item, index) {
                  return _c("img", {
                    key: index,
                    attrs: { src: item, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.handlePreviewImg(row.imgList, index)
                      }
                    }
                  })
                }),
                0
              )
            }
          },
          {
            key: "itemTradeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "color-green" }, [
                  _vm._v(_vm._s(row.tradePrice) + "元")
                ]),
                _c(
                  "div",
                  { class: _vm.tradingPlatformClassType[row.radingType] },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedTradingPlatformTypeMapText(row.radingType)
                      )
                    )
                  ]
                ),
                _c("div", { staticClass: "color-black" }, [
                  _vm._v(_vm._s(row.time))
                ])
              ])
            }
          },
          {
            key: "itemRateDescSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.handleReloadRatingInfo }
                      },
                      [_vm._v("重新获取评级信息")]
                    )
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handleMoveBookVersion(row)
                        }
                      }
                    },
                    [_vm._v("挪动")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-20",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEditorImgStore(row)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "是否确认删除?",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleDeleteImgStore(row)
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        { staticClass: "ml-20", attrs: { type: "danger" } },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("AddOrEditorPopup", {
        ref: "addOrEditorPopupEl",
        on: { success: _vm.handleSuccess }
      }),
      _c("MoveVersionByImgStore", { ref: "moveVersionByImgStoreEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }