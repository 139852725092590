<template>
  <div>
    <a-table
        :columns="tableList"
        :data-source="list"
        :pagination="pagination"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!-- 图片性质  -->
      <div slot="itemNatureSlot" slot-scope="row">
        <span :class="natureTypeClassType[row.natureType]">{{ natureTypeListMapText(row.natureType) }}</span>
      </div>
<!-- 图片列表     -->
      <div slot="itemImgListSlot" slot-scope="row">
        <img
            v-for="(item, index) in row.imgList"
            :key="index"
            :src="item"
            alt=""
            @click="handlePreviewImg(row.imgList, index)"
        >
      </div>
<!--  成交信息    -->
      <div slot="itemTradeSlot" slot-scope="row">
        <div class="color-green">{{ row.tradePrice }}元</div>
        <div :class="tradingPlatformClassType[row.radingType]">{{ computedTradingPlatformTypeMapText(row.radingType) }}</div>
        <div class="color-black">{{ row.time }}</div>
      </div>
<!--   评级信息   -->
      <div slot="itemRateDescSlot" slot-scope="row">

        <div>
          <a-button
              type="primary"
              size="small"
              @click="handleReloadRatingInfo"
          >重新获取评级信息</a-button>
        </div>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button
            @click="handleMoveBookVersion(row)"
        >挪动</a-button>
        <a-button
            class="ml-20"
            type="primary"
            @click="handleEditorImgStore(row)"
        >编辑</a-button>
        <a-popconfirm
            title="是否确认删除?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteImgStore(row)"
        >
          <a-button class="ml-20" type="danger">删除</a-button>
        </a-popconfirm>
      </div>

    </a-table>
<!--  添加或编辑图库  -->
    <AddOrEditorPopup ref="addOrEditorPopupEl" @success="handleSuccess"/>
<!--  挪动  -->
    <MoveVersionByImgStore ref="moveVersionByImgStoreEl"/>
  </div>
</template>
<script>
import {coinBookImgStoreListTableColumns} from "@/views/cmsPage/coinBookManage/ImgStoreManage/_data"
import AddOrEditorPopup from "@/views/cmsPage/coinBookManage/ImgStoreManage/_components/AddOrEditorPopup/index.vue"
import {
  NATURE_TYPE_CLASS_TYPE,
  TRADING_PLATFORM_CLASS_TYPE,
  tradingPlatformTypeMapText
} from "@/views/cmsPage/coinBookManage/_data"
import MoveVersionByImgStore
  from "@/views/cmsPage/coinBookManage/ImgStoreManage/_components/MoveVersionByImgStore/index.vue"
import {deleteListByCoinBook} from "@/views/cmsPage/coinBookManage/ImgStoreManage/_apis"
import {objArrMapObj} from "@/untils/otherEvent"

export default {
  props: ['list', 'pagination', "imgTypeList"],
  components: {AddOrEditorPopup, MoveVersionByImgStore},
  data() {
    return {
      tableList: coinBookImgStoreListTableColumns,
      natureTypeClassType: NATURE_TYPE_CLASS_TYPE,
      tradingPlatformClassType: TRADING_PLATFORM_CLASS_TYPE,
    }
  },
  computed: {
    computedTradingPlatformTypeMapText: () => {
      return (value) => tradingPlatformTypeMapText(value)
    }
  },
  methods: {
    natureTypeListMapText(value) {
      return objArrMapObj('value', 'name', this.imgTypeList)[value]
    },
    /** 重新获取评级数据 */
    handleReloadRatingInfo() {

    },
    /** 编辑 */
    handleEditorImgStore(row) {
      this.$refs.addOrEditorPopupEl.show(row)
    },
    /** 挪动版别 */
    handleMoveBookVersion(row) {
      this.$refs.moveVersionByImgStoreEl.show(row)
    },
    /** 删除 */
    async handleDeleteImgStore(row) {
      const res = await deleteListByCoinBook({ id: row.id })
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        current: index,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
</style>